<template>
  <div class="container" fluid="lg">
    <b-form @submit.prevent="makeAppointment">
      <div v-if="application" class="row">
        <div class="col-12 col-md-6">
          <div class="row mt-4">
            <h5 class="col-12 text-center mt-2">
              {{ $t('loan_information') }}
            </h5>

            <div class="col-12 mt-4">
              <div class="small">{{ $t('loan_size') }}</div>
              <h6 class="font-weight-bold">{{ application.approved_amount | Currency }} {{ $t('bahts') }}</h6>
            </div>

            <div class="col-12 mt-4">
              <div class="small">{{ $t('loan_term') }}</div>
              <h6 class="font-weight-bold">{{ application.loan_term }} {{ $t('months') }}</h6>
            </div>
            <div class="col-12 mt-4">
              <div class="small">{{ $t('status') }}</div>
              <h6 class="font-weight-bold">{{ $t('pre_approve_branch') }}</h6>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="row">
            <h5 class="col-12 text-center mt-4">
              {{ $t('appointment_information') }}
            </h5>

            <div class="col-12 pt-4">
              <h6>{{ $t('appointment_branch.label') }}</h6>
              <h5 class="fw-bold">{{ appointment_branch }}</h5>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('appointment_date.label') }}
                  <span class="text-danger">*</span>
                </h6>
                <get-help
                  modal_id="modal-ad"
                  title="What do we mean by 'name'"
                  :content="$t('appointment_date.help')"
                />
              </div>

              <VueDatePicker
                ref="menu"
                v-model="date"
                :format="format"
                :min-date="minDate"
                :max-date="maxDate"
                fullscreen-mobile
                color="#2E3192"
                @onOpen="menu = true"
                @onClose="menu = false"
              >
                <template #activator>
                  <div class="position-relative w-100">
                    <b-form-input
                      v-model.trim="$v.appointment_date.$model"
                      v-mask="'####-##-##'"
                      size="sm"
                      :class="{ error: $v.appointment_date.$error }"
                      disabled
                    ></b-form-input>

                    <i class="append-icon ri-calendar-2-line ri-lg"></i>
                  </div>
                </template>
              </VueDatePicker>

              <!-- <div class="small text-light">{{ $t("appointment_date.label") }}</div> -->
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('appointment_time.label') }}
                  <span class="text-danger">*</span>
                </h6>
              </div>
              <multiselect
                v-model.trim="$v.appointment_time.$model"
                :class="{ error: $v.appointment_time.$error }"
                deselect-label
                :placeholder="$t('select_one')"
                :options="appointment_time_options"
                :searchable="false"
                :allow-empty="false"
                :show-labels="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
              </multiselect>
              <!-- <div class="small text-light">{{ $t("appointment_time.example") }}</div> -->
            </div>

            <div class="row mt-auto mx-auto pt-4">
              <div class="col-12 py-4 text-center">
                <b-button type="submit" pill variant="primary px-4">{{ $t('make_appointment') }}</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import '@/assets/scss/vue-datepicker.min.css'
import '@/assets/scss/vue-multiselect.min.scss'
import { required } from 'vuelidate/lib/validators'
import GetHelp from '@/components/GetHelp.vue'
import dateFormat from '@/mixins/dateFormat.js'
import LoanService from '@/api/service/LoanService'
import CommonService from '@/api/service/CommonService'

const date = new Date()
export default {
  components: { Multiselect, GetHelp },
  mixins: [dateFormat],
  data() {
    return {
      menu: false,
      date,
      minDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1}`,
      maxDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 14}`,
      format: 'YYYY-MM-DD',
      appointment_date: null,
      appointment_time: null,
      appointment_time_options: [],
      appointment_branch: '',
      selectedDate: null,
      application: null,
      branchOptions: [],
    }
  },
  watch: {
    date: function (val) {
      this.appointment_date = val
      this.selectedDate = val
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.menu.$refs.agenda.mode = 'year'))
    },
  },
  created() {
    this.getInitData()
  },
  methods: {
    showDatePicker() {
      this.$bvModal.show('modal-date-picker')
    },
    async getInitData() {
      try {
        this.isBusy = true
        const branchResponse = await CommonService.getBranch()
        this.branchOptions = branchResponse.data.data

        this.getLoanApplication()

        this.isBusy = false
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getLoanApplication() {
      try {
        this.isBusy = true
        const res = await LoanService.loanApplication()
        this.isBusy = false
        if (res.status == 200) {
          const loanResponse = res.data.data
          this.application = loanResponse
          if (loanResponse) {
            // loanResponse.to_appointment_branch
            if (loanResponse.status == 'pre-approved') {
              this.appointment_time = loanResponse.appointment_branch_time
              this.appointment_date = loanResponse.appointment_branch_date
              this.date = loanResponse.appointment_branch_date

              const timeSlot = loanResponse.branch_section_time_slot

              for (var key of Object.keys(timeSlot)) {
                this.appointment_time_options.push(timeSlot[key])
              }

              const branchId = loanResponse.branch_code
              const branch = this.branchOptions.find((branch) => branch.id == branchId)
              this.appointment_branch =
                this.$i18n.locale == 'mm' ? branch.name_mm : this.$i18n.locale == 'en' ? branch.name_en : branch.name_th
            } else {
              this.$router.push({ name: 'home' })
            }
          } else {
            this.$router.push({ name: 'home' })
          }
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async makeAppointment() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.isBusy = true
        try {
          await LoanService.branchAppointment({
            date: this.selectedDate,
            time: this.appointment_time,
          })

          this.isBusy = false
          this.$router.push({ name: 'home' })
        } catch (err) {
          this.isBusy = false
          this.showAlert(err)
        }
      }
    },
  },
  validations: {
    appointment_date: {
      required,
    },
    appointment_time: {
      required,
    },
  },
}
</script>

<style></style>
